import { graphql } from "gatsby";
import DocumentationPage from "../../layouts/WithSideBarPage/_documentationPage/index";

const Terms = (data) => {
  const { htmlAst, frontmatter } = data.data.markdownRemark;
  return (
    <DocumentationPage
      seo={frontmatter.seo[0]}
      // bannerTitle={frontmatter.bannerTitle}
      sidebarList={frontmatter.sidebarList}
      contents={htmlAst}
    />
  );
};

export default Terms;

export const query = graphql`
  query getTerms($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        seo {
          link
          title
          noForRobots
        }
        bannerTitle
        sidebarList {
          description
          to
        }
      }
    }
  }
`;
